import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutomTimeAgo'
})
export class CustomTimeAgoPipe implements PipeTransform {
  transform() {
    throw new Error('Method not implemented.');
  }

}


